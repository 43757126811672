import React, { useState } from "react";
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { resetMenu } from "../components/menu"
import { setGradient } from "../components/gradient"

const IndexPage = () => {
  const [emailAddressField, setEmailAddressField] = useState('');
  const handleEmailAddressFieldChange = (e) => setEmailAddressField(e.target.value);
  resetMenu();
  setGradient({
    fromColor: '#F9F9F9',
    toColor: '#ffffff'
  })

  return (
    <Layout>
      <SEO title="Zero-party data toolkit for publishers" />
      <div className="w-screen md:ml-0 md:mr-0 mt-64 -mb-24 z-0 absolute">
        <div className="audyem-key"></div>
      </div>

      <div className="max-w-3xl pt-4 mx-auto relative text-center">
        <div className="mx-4">
          
          <div className="max-w-lg md:mt-8 mb-6 text-center mx-auto">
            <h1 className="mb-4 font-semibold text-black">
              The zero-party data toolbox for publishers
            </h1>
          </div>
          <p className="max-w-2xl text-lg leading-snug text-gray-900 mx-auto text-center">
            <span className="text-red-600 font-bold">Before:</span> Gathering zero-party data is tedious, with cumbersome data plumbing and off-brand widget providers. <span className="text-green-900 font-bold">Audyem:</span> All of your needs from widgets to event pipelines and dashboards in one place.
          </p>
          <Link to="/request">
            <button className="text-base mt-8 px-10 py-2 flex-shrink-0 transition duration-200 ease-in-out bg-black hover:bg-gray-900 text-white rounded-full inline cursor-pointer outline-none font-bold audyem-nudge" type="submit">
              Try Audyem for free <span className="audyem-nudge-elem pr-3">→</span>
            </button>
          </Link>
          <span className="w-40 mx-auto block mt-2 leading-snug text-xxs text-gray-800">
            Psst. We’re pushing revenue at 70+ companies
          </span>
        </div>
      </div>

      <div className="max-w-100 mx-auto relative z-10 text-center">
        <a id="product_start" href="#product_start"> </a>
        <div className="audyem-widgets mx-auto ml-0"></div>
      </div>

      <div className="max-w-100 w-screen absolute audyem-bg-wave -mt-32 pt-32 pb-24"></div>
      
      <div className="w-screen bg-white">
        <div className="w-screen mt-20 mx-auto relative z-10 text-center">
          <div className="max-w-2xl px-2 mx-auto z-0 pt-0 md:pt-8 flex flex-wrap md:flex-no-wrap justify-center">
            <div className="audyem-partners audyem-partners--1"></div>
            <div className="audyem-partners audyem-partners--2"></div>
            <div className="audyem-partners audyem-partners--3"></div>
            <div className="audyem-partners audyem-partners--4"></div>
            <div className="audyem-partners audyem-partners--5"></div>
            <div className="audyem-partners audyem-partners--6"></div>
            <div className="audyem-partners audyem-partners--7"></div>
          </div>
        </div>
      </div>

   <div className="max-w-100 w-screen bg-white">
     <div className="max-w-3xl mx-auto relative z-10 mb-0 md:mb-4 text-center">
          <div className="mx-4">
            <div className="max-w-lg pt-8 mb-6 text-center mx-auto">
              <h3 className="text-green-800 font-bold mb-2 text-sm">
                Customers love us
              </h3>
              <h2 className="max-w-md mb-4 font-semibold text-black mx-auto">
                Helping top publishers generate more value
              </h2>
            </div>
            <p className="max-w-lg text-lg leading-snug mx-auto text-center text-gray-800">
              Ippen Media reported a 25% uplift in their lead generation using Audyem and 95% time saved by reducing overhead, administration and freeing up developer resources. Read the success story to learn more. 
            </p>
            <Link to="#product_start">
            <button className="text-sm mt-8 px-10 py-2 flex-shrink-0 transition duration-200 ease-in-out bg-black hover:bg-gray-900 text-white rounded-full inline cursor-pointer outline-none font-bold audyem-nudge" type="submit">
              Read the case study <span className="audyem-nudge-elem pr-2">→</span>
            </button>
          </Link>
          </div>
        </div>

        <div className="max-w-3xl pt-4 mx-auto relative z-10 mb-0 md:mb-4 text-center">
          <div className="mx-4">
            <div className="max-w-lg mt-8 mb-6 text-center mx-auto">
              <h3 className="text-red-500 font-bold mb-2 text-sm">Best-in-class</h3>
              <h2 className="max-w-sm mb-4 font-semibold text-black mx-auto">
                Learning from 100 million users every month
              </h2>
            </div>
            <p className="max-w-lg text-lg leading-snug mx-auto text-center text-gray-800">
              Audyem has grown to over 100 million users, processing billions of events on behalf of our customers. Through constant monitoring of our growing network, we deliver best-in-class performance.
            </p>
          </div>
        </div>

        <div className="max-w-3xl pt-4 mx-auto relative z-10 mb-0 md:mb-4 text-center">
          <div className="mx-4">
            <div className="max-w-lg mt-8 mb-6 text-center mx-auto">
              <h3 className="text-blue-500 font-bold mb-2 text-sm">Integration</h3>
              <h2 className="max-w-sm mb-4 font-semibold text-black mx-auto">
                Perfectly in tune with your CRM
              </h2>
            </div>
            <p className="max-w-lg text-lg leading-snug mx-auto text-center text-gray-800">
              Integrating our widgets requires just one line of code. Your data gets delivered instantly to any CRM interface you might desire, from SAP Sales Cloud to Salesforce or custom event architectures.
            </p>

            <table className="text-left rounded-lg mt-8 mx-auto" style={{boxShadow: 'inset 5px 6px 5px rgb(1 1 1 / 2%)', fontSize: '.85rem'}}>
              <tbody className="align-top">
                <tr className="border-b-6 border-white align-top">
                  <td className="font-bold text-gray-800 pt-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">⚡️</span> &nbsp; Data Streaming</td>
                  <td className="pr-4 py-2 text-gray-800 pt-3">Your events get delivered instantly</td>
                </tr>
                <tr className="border-b-6 border-white align-top">
                  <td className="font-bold text-gray-800 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">🤖</span> &nbsp; Webhook Delivery</td>
                  <td className="pr-4 py-2 text-gray-800">Get events via JSON to your webhook endpoints</td>
                </tr>
                <tr className="border-white align-top">
                  <td className="font-bold text-gray-800 pb-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">🧠</span> &nbsp; Custom Payloads</td>
                  <td className="pr-4 py-2 text-gray-800 pb-3 ">Include your system logic everywhere</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="max-w-3xl pt-4 mx-auto relative z-10 mb-0 md:mb-4 text-center">
          <div className="mx-4">
            <div className="max-w-lg mt-8 mb-6 text-center mx-auto">
              <h3 className="text-yellow-500 font-bold mb-2 text-sm">Audience Features</h3>
              <h2 className="max-w-sm mb-4 font-semibold text-black mx-auto">
                Keeping it personal
              </h2>
            </div>
            <p className="max-w-lg text-lg leading-snug mx-auto text-center text-gray-800">
              Audyem lets you place the right message at the right time through a wide range of dynamic features. Drastically lower bounce rates and increased sales. In a fully automated fashion. 
            </p>

            <table className="text-left rounded-lg mt-8 mx-auto" style={{boxShadow: 'inset 5px 6px 5px rgb(1 1 1 / 2%)', fontSize: '.85rem'}}>
              <tbody className="align-top">
                <tr className="border-b-6 border-white align-top">
                  <td className="font-bold text-gray-800 pt-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">🎓</span> &nbsp; Article Clustering</td>
                  <td className="pr-4 py-2 text-gray-800 pt-3">We match your widgets to your context on the fly</td>
                </tr>
                <tr className="border-b-6 border-white align-top">
                  <td className="font-bold text-gray-800 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">🙋‍♂️</span> &nbsp; Bounce Prevention</td>
                  <td className="pr-4 py-2 text-gray-800">Detect exit intent or trigger messages based on your events </td>
                </tr>
                <tr className="border-white align-top">
                  <td className="font-bold text-gray-800 pb-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">💥</span> &nbsp; Funnel Awareness</td>
                  <td className="pr-4 py-2 text-gray-800 pb-3 ">Show dynamic messages based on signup status</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="max-w-3xl pt-4 mx-auto relative z-10 mb-0 md:mb-4 text-center">
          <div className="mx-4">
            <div className="max-w-lg mt-8 mb-6 text-center mx-auto">
              <h3 className="text-green-700 font-bold mb-2 text-sm">Performance</h3>
              <h2 className="max-w-sm mb-4 font-semibold text-black mx-auto">
                Blazing fast
              </h2>
            </div>
            <p className="max-w-lg text-lg leading-snug mx-auto text-center text-gray-800">
              Audyem lets you craft widgets that get perfect performance scores across the board, making us the ideal choice for all of your SEO-sensitive applications.
            </p>

            <table className="text-left rounded-lg mt-8 mx-auto" style={{boxShadow: 'inset 5px 6px 5px rgb(1 1 1 / 2%)', fontSize: '.85rem'}}>
              <tbody className="align-top">
                <tr className="border-b-6 border-white align-top">
                  <td className="font-bold text-gray-800 pt-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">💯</span> &nbsp; Top Ratings</td>
                  <td className="pr-4 py-2 text-gray-800 pt-3">Perfect Lighthouse marks for performance and best practices</td>
                </tr>
                <tr className="border-b-6 border-white align-top">
                  <td className="font-bold text-gray-800 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">❤️</span> &nbsp; Core Web Vitals</td>
                  <td className="pr-4 py-2 text-gray-800">Audyem ensures ideal user experience in the frontend</td>
                </tr>
                <tr className="border-white align-top">
                  <td className="font-bold text-gray-800 pb-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">🐁</span> &nbsp; Tiny Payloads</td>
                  <td className="pr-4 py-2 text-gray-800 pb-3 ">Instant display with payloads under 65 kilobytes</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="max-w-3xl pt-4 mx-auto relative z-10 pb-20 text-center">
          <div className="mx-4">
            <div className="max-w-lg mt-8 mb-6 text-center mx-auto">
              <h3 className="text-red-500 font-bold mb-2 text-sm">Free Trial</h3>
              <h2 className="max-w-sm mb-4 font-semibold text-black mx-auto">
                Join our publishers and maximize revenue
              </h2>
            </div>
            <p className="max-w-lg text-lg leading-snug mx-auto text-center text-gray-800">
              See and feel what it’s like to create and deliver campaigns with Audyem. Evaluate our software for free — we’re throwing in one month of consulting and support.
            </p>
            <input onChange={handleEmailAddressFieldChange} value={emailAddressField} className="mt-6 md:mt-0 w-56 md:w-64 bg-gray-100 text-sm outline-none px-3 py-2 focus rounded-lg md:rounded-l-lg md:rounded-r-none placeholder-gray-700" placeholder="your@email.com"></input>
            <Link to="/request" state={{ emailAddress: emailAddressField }}>
              <button className="text-sm mt-2 w-56 md:mt-8 px-10 py-2 flex-shrink-0 transition duration-200 ease-in-out bg-black hover:bg-gray-900 text-white rounded-lg md:rounded-r-lg md:rounded-l-none inline cursor-pointer outline-none font-bold audyem-nudge" type="submit">
                Request your trial <span className="audyem-nudge-elem pr-2">→</span>
              </button>
            </Link>
            <span className="w-56 mx-auto block mt-2 leading-snug text-xxs text-gray-800">
              Or <a href="mailto:hi@audyem.com" className="underline">talk to us personally</a>. Ask anything.
            </span>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default IndexPage
